export enum ApiEndpoint {
    login = 'login',
    forgotpassword = 'sendmail',
    resetpassword = 'generate-password?',
    userresetpassword = 'reset-password?',
    checkToken = 'checkToken?token=',
    customLogin = 'customLogin',

    loadDropdownList = 'usersearchdata',
    loadAdvSearchData = '',
    campaignsforcalendar = 'campaignsforcalendar',
    tacticsview = 'tacticsview',
    updatetacticsendDate = 'updatetacticsendDate',
    getalldropdowns = 'usersearchdata',
    tacticDetails = 'tacticDetails',
    tacticslistcampaignsview = 'tacticslistcampaignsview',
    tacticDetailDropdownValues = 'mapvalues',
    fetchCampaignSliderDetails = 'fetchCampaignSliderDetails',
    fetchpresetFilters = 'getpresetfilters',
    share = 'share',
    addPresetFilter = 'addpresetfilter',
    deletePresetFilter = 'deletepresetfilter',

    loadAllPrograms = 'allprogramdetails',
    getUserData = 'getuser',
    loadCamps = 'getcampaignsbyprogramidanduserinfo',
    loadTactics = 'gettacticbyprogramid',
    getTacticsByProgramID = 'gettacticbyprogramid',
    getTacticByCampaignID = 'gettacticbycampaignid',
    getTacticByTacticID = 'gettacticbytacticid',
    getprogrambyprogramid = 'getprogrambyprogramid',
    getCampaignsByProgramIDandUserInfo = 'getcampaignsbyprogramidanduserinfo',
    getCampaignByCampaignID = 'getcampaignbycampaignid',
    deleteProgram = 'deleteprogram',
    deleteCampaign = 'deletecampaign',
    deleteTactic = 'deletetactic',
    loadAllData = 'getall',
    getDraftsData = 'drafts',
    saveTacticDetails = 'tactic',
    getAllTacticDropdownValues = 'mapvalues',
    getWaveDetailByTacticId = 'getwavesbytacticid',
    getVersionList = 'getVersionNumberdropdowns',
    edit_log_history = 'editLogsHistory',
    deleteWaveDetailData = 'deletewave',
    saveWaveDetailData = 'tacticwave',
    getExposureDetail = 'exposureinwaves',
    addProgram = 'program',
    gettacticsenddate = 'gettacticsenddate',

    saveExposureDetail = 'exposuredetails',
    saveNewCampaign = 'campaign',

    loadAllTilesData = 'datacount',

    deleteProgramData = 'deleteprogram',
    deleteCampaignData = 'deletecampaign',
    deleteTacticData = 'deletetactic',
    deleteWaveData = 'deletewave',
    getprogramsbysearch ="getprogramsbysearch",
    gettacticsbysearch ="gettacticsbysearch",
    setTacticData ="setTacticData",
    getProgramswithCampaign = "programswithcampaign",
    getTooltips = 'gettooltips',

    /* =================== Admin API =================================== */

    getTileCounts = 'admintilescount',
    updateRequestStatus = 'updateRequestStatus',
    updateShareRequestStatus = 'update_share_campaign',
    getAllRequests = 'fetchAllRequests',
    getAllUserRequests = 'allUserRequests',
    rejectRequest = 'reject-request',
    usersArray = 'usersArray',
    getAllLovs = 'lovs',
    lovlist = 'lovlist',
    userLovs = 'userLovs',

    activateDeactiveUser = 'activateDeactivateUser',
    getUserDetails = 'users',
    addEditUser = 'adduser',
    setDefaultPassword = 'setDefaultPassword',
    createUserEditRequest = 'userEditRequests',
    createUserAddRequest = 'userAddRequests',
    allUsers = 'users',
    newRequestCloseNotification = 'request-complete',
    triggerEditUserEmailNotification = 'edit-user',
    triggerDeactivateUserEmailNotification = 'deactivate-user',
    triggerActivateUserEmailNotification = 'activate-user',
    triggerAddUserEmailNotification = 'create-user',

    /** ===============  LOVs API ====================== */
    addLovs = 'addlov',
    editLovs = 'editlov',
    deleteLov = 'deletelov',
    getAllLovRequests = 'fetchLOVRequests',
    createLovEditRequest = 'lovEditRequests',
    createLovAddRequest = 'lovAddRequests',

    /**  =============== New Request Notifications ============= */
    newRequestNotification = 'new-request',
    getCampaignsWithProgramDetails = 'getAllCampaignsWithProgramDetails',
    miscAddRequest = 'miscAddRequest',
    allMiscRequests = 'allMiscRequest',
    allAppprovedMisc = 'allAppprovedMisc',
    getExtracts = 'extract',

    getExpCodesInTacticId = 'getExpCodesInTacticId',
    miscApproveRequest = 'approvebulkupload',
    addWaveDetails = 'addwavedetails',
    addwaveofferdetails = 'addwaveofferdetails',
    getNotifications = 'getNotifications',
    deleteNotification = 'deleteNotification',
    getNotificationCount = 'getNotificationCount',
    setSeenNotifications = 'setSeenNotifications',
    getUserNotifications = 'getUserNotifications',
    deleteUserNotification = 'deleteUserNotification',

    /** ===============  CMAPS API ====================== */
    getFilterValues = 'getFilterData',
    getGridData = 'getGridData',
    technicalTags = 'getTechnicalTags',
    derivedTags = 'getDerivedTags',
    saveDerivedTags = 'saveDerivedTags',

    /** ============== Veeva Sync API ================== */
    getVeevaSync = 'veevaSync',

    bulkUpload = 'bulkUpload',
    getWriteBack = 'writeBack',
    uploadStatusCheck = 'bulkuploadstatus',

    /** ============= Bulk Export ============ */
    queryAllData = 'queryAllData',
    downloadExtractData = 'downloadExtractData',

    /**======== CP code apis =======*/
    getCpcodedropdowns = 'getCpcodedropdowns',
    getCpcodedetails = 'getCpcodedetails',

    // Survey Form Feedback
    getSurveyStatus = 'getSurveyStatus',
    surveyForm = 'surveyform',
    taxonomysheetdownload = 'taxonomysheetdownload',

    //jet download
    jetfiledownload = 'jetfiledownload',
    getjetfiles = 'getJetFiles',
    getAuditReports = 'getAuditReports',
    auditfiledownload = 'auditfiledownload',
    //training download
    trainingVideo = 'trainingVideo',
    // share campaign
    shareCampaign = 'share_campaign',
    getAssetData = 'getAssetData',
    getFileContent = 'getfilecontent',

    copyTactics = 'copytactics',
    copytacticsstatus = 'copytacticsstatus'


}

